import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import muse from '../assets/images/museLogo.svg'
import prima from '../assets/images/primaLogo.svg'
import fineart from '../assets/images/fineArtLogo.svg'
import { Link } from "react-router-dom";
import stars from "../assets/images/black-star.svg"

const Partner = () => {
  return (
    <>
      <div className="partner">
        <Container>
          <Row>
            <Col lg="12 " className="panelHead">
              <h3>Partners</h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
          <img src={stars} className="row-str"  width="auto" height="auto" />

            <Col lg="3">
              <div className="partnerBox muse">
              <img src={muse} alt="muse" />
              <p>
              Muse Frame is a dedicated digital fine art frame with software to seamlessly showcase NFTs at the click of a button.
              </p>
              <Link to={'https://www.museframe.io/?sca_ref=3907145.TrxZyaxgcb'} target="_blank">Learn More</Link>
                <svg
                className="partnerDesign"
                  width="414"
                  height="105"
                  viewBox="0 0 414 102"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                    
                  />
                </svg>
              </div>
            </Col>
            <Col lg="3">
              <div className="partnerBox prima">
              <img src={prima} alt="muse" width={150} />
              <p>
              PrimaFelicitas is an AI, Web3 & Blockchain Software Development Company dedicated to enabling digital transformation.
              </p>
              <Link to={'https://www.primafelicitas.com/'} target="_blank">Learn More</Link>
              <svg
                className="partnerDesign"
                  width="414"
                  height="105"
                  viewBox="0 0 414 102"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                   
                  />
                </svg>
              </div>
            </Col>
            <Col lg="3">
              <div className="partnerBox fineart">
              <img src={fineart} alt="muse" />
              <p>
              Fine Art Publishing is proud to represent artists of the highest caliber and present their incredible artwork.
              </p>
              <Link to={'https://www.fineart.pub/'} target="_blank">Learn More</Link>
              <svg
                className="partnerDesign"
                  width="414"
                  height="105"
                  viewBox="0 0 414 102"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 8.79831L17.25 18.8193C34.5 28.8403 69 48.8824 103.5 50.8866C138 52.8908 172.5 36.8571 207 22.8277C241.5 8.79831 276 -3.2269 310.5 0.781507C345 4.78991 379.5 24.8319 396.75 34.8529L414 44.8739V90C414 98.2843 407.284 105 399 105H396.75C379.5 105 345 105 310.5 105C276 105 241.5 105 207 105C172.5 105 138 105 103.5 105C69 105 34.5 105 17.25 105H15C6.71573 105 0 98.2843 0 90V8.79831Z"
                   
                  />
                </svg>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Partner;
