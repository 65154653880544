import React from 'react'
import leftImg from '../assets/images/discountLeft.png'
import rightImg from '../assets/images/discountRight.png'
import stars from "../assets/images/black-star.svg"

const Referral = () => {
  return (
    <div className='referralMain'>
       <div className='leftimg'>
        <img src={leftImg} alt='' />
       </div>
       <div className='contentBox'>
        <h3>Refer fellow artists </h3>
        <h4>Get a Discount of 7%</h4>
        <p>Join our Referral Program and enjoy a 7% discount for both you and your friend on your next NFT purchase or sale.</p>
        <p>With unlimited referrals and ongoing rewards, share the excitement with your network today!</p>
       </div>
       <div className='rightBox'>
       <img src={stars} className="right-star"  width="auto" height="auto" />

       <img src={rightImg} alt='' />

       </div>


    </div>
  )
}

export default Referral

