import React, { useEffect, useState } from "react";
import art1 from "../assets/images/NewArt1.png";
import art2 from "../assets/images/NewArt2.png";
import art3 from "../assets/images/NewArt3.png";
import art4 from "../assets/images/NewArt4.png";
import profile1 from "../assets/images/seno.svg";
import profile2 from "../assets/images/uwem.svg";
import profile3 from "../assets/images/ravi.svg";
import profile4 from "../assets/images/haward.svg";
import arrow from "../assets/images/longArrow.svg";
import maticIcon from "../assets/images/maticIcon.svg";
import eye from "../assets/images/eye.svg";
import like from "../assets/images/like.svg";
import star from "../assets/images/star.svg"


import { Col, Container, NavLink, Row } from "react-bootstrap";

import cardImg from "../assets/images/jpg/learningCartImg.png";
import { Link } from "react-router-dom";
import axios from "axios";
import 'toastr/build/toastr.min.css';
import toastr from "toastr"
function TrandingArtwork() {
  const [trendingArtworks, setTrendingArtWorks] = useState([])
  useEffect(()=>{
    axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/sellItems/trendingArtWork`, {}).then((response) => {
      if (response?.data?.status === "success") {

        console.log("heyreq", response)
        setTrendingArtWorks(response?.data?.data)
      }
      else {
        return toastr.error("Something went wrong")
      }
    })
}, [])
  return (
    <div className=" trandingArtwork">
      <Container>
        <Row>
          <Col lg="12 panelHead viewAll">
          <img src={star} className="starss"  width="auto" height="auto" />
            <h3>Trending Artworks</h3>
            <p className="mt-4">
              Explore our curated selection of premium artworks.
            </p>
            <Link to={'https://market.terpnash.com/art-genres'} target="_blank">View All <svg width="42" height="16" viewBox="0 0 42 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 7C0.447715 7 -5.30158e-08 7.44772 0 8C5.30158e-08 8.55228 0.447715 9 1 9L1 7ZM41.7071 8.7071C42.0976 8.31658 42.0976 7.68341 41.7071 7.29289L35.3431 0.928929C34.9526 0.538405 34.3195 0.538405 33.9289 0.928929C33.5384 1.31945 33.5384 1.95262 33.9289 2.34314L39.5858 8L33.9289 13.6569C33.5384 14.0474 33.5384 14.6805 33.9289 15.0711C34.3195 15.4616 34.9526 15.4616 35.3431 15.0711L41.7071 8.7071ZM1 9L41 9L41 7L1 7L1 9Z" fill="black" />
            </svg>

            </Link>
          </Col>
          <Col lg="12 margintopfeature">
            <Row className="justify-content-center">
              {trendingArtworks.length>0 && trendingArtworks.map((item , ind)=>
              <Col lg="3" key={ind}>
                <NavLink className="text-decoration-none" href={`https://market.terpnash.com/nftsale/${item?._id}`} target="_blank">
                  <div className="trandingArt">
                    <div className="imgBox">
                      <div className="artistNameBox">
                        <img src={item?.owner_id?.profileImage?.link} alt="" /><h5>{item?.owner_id?.name+" "+ item?.owner_id?.lastname}</h5>
                      </div>
                      <img src={item?.nft_id?.image_id?.image?.water_mark_image_link} alt="" />
                    </div>
                    <div className="contentBox">
                      <h3 title="Rhythms Of Nature">{item?.nft_id?.name}</h3>
                      <div className="artPriceBox">
                        <div className="artRate">
                          <h4>{item?.price}</h4>
                          <img src={maticIcon} width={30} height={30} alt="" />
                        </div>
                        <ul>
                          <li className="d-flex gap-2 align-items-center">
                            <img src={eye} width={25} height={25} alt="" className="mt-1"/>
                            <h5>{item?.view_count}</h5>
                          </li>
                          <li className="d-flex gap-2 align-items-center">
                            <img src={like} width={25} height={25} alt="" className="mt-1" />
                            <h5>{item?.like_count}</h5>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </Col>
)}
              {/* <Col lg="3">
                <NavLink className="text-decoration-none" href="https://market.terpnash.com/nftsale/65f053297ec020d000a0a72e" target="_blank">
                  <div className="trandingArt">
                    <div className="imgBox">
                      <div className="artistNameBox">
                        <img src={profile2} alt="" /> <h5>Uwem</h5>
                      </div>
                      <img src={art2} alt="" />
                    </div>
                    <div className="contentBox">
                      <h3 title="Royal Couple">Royal Couple</h3>
                      <div className="artPriceBox">
                        <div className="artRate">
                          <h4>300</h4>
                          <img src={maticIcon} width={30} height={30} alt="" />
                        </div>
                        <ul>
                          <li>
                            <img src={eye} width={25} height={25} alt="" />
                            <h5>10.1k</h5>
                          </li>
                          <li>
                            <img src={like} width={25} height={25} alt="" />
                            <h5>8.2k</h5>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </Col>
              <Col lg="3">
                <NavLink className="text-decoration-none" href="https://market.terpnash.com/nftsale/65f1ed647ec020d000a0c8a9" target="_blank">
                  <div className="trandingArt">
                    <div className="imgBox">
                      <div className="artistNameBox">
                        <img src={profile3} alt="" /> <h5>Ravi </h5>
                      </div>
                      <img src={art3} alt="" />
                    </div>
                    <div className="contentBox">
                      <h3 title="Lion’s Floral Crown - King">Lion’s Floral Crown - King</h3>
                      <div className="artPriceBox">
                        <div className="artRate">
                          <h4>500</h4>
                          <img src={maticIcon} width={30} height={30} alt="" />
                        </div>
                        <ul>
                          <li>
                            <img src={eye} width={25} height={25} alt="" />
                            <h5>10.1k</h5>
                          </li>
                          <li>
                            <img src={like} width={25} height={25} alt="" />
                            <h5>8.2k</h5>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </Col>
              <Col lg="3">
                <NavLink className="text-decoration-none" href="https://market.terpnash.com/nftsale/65c68c256e2fbb3890ea62da" target="_blank">
                  <div className="trandingArt">
                    <div className="imgBox">
                      <div className="artistNameBox">
                        <img src={profile4} alt="" /> <h5>Howard  </h5>
                      </div>
                      <img src={art4} alt="" />
                    </div>
                    <div className="contentBox">
                      <h3 title="Mystery Of The Underwater People">Mystery Of The Underwater People</h3>
                      <div className="artPriceBox">
                        <div className="artRate">
                          <h4>150</h4>
                          <img src={maticIcon} width={30} height={30} alt="" />
                        </div>
                        <ul>
                          <li>
                            <img src={eye} width={25} height={25} alt="" />
                            <h5>10.1k</h5>
                          </li>
                          <li>
                            <img src={like} width={25} height={25} alt="" />
                            <h5>8.2k</h5>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TrandingArtwork;
